window.addEventListener("DOMContentLoaded", () => {
  // handle expand accordion item
  document.addEventListener("click", e => {
    if (e.target.closest(".accordion-list__item__header")) {
      if (e.target.classList.contains("expanded")) {
        e.target.classList.remove("expanded");
        e.target.nextElementSibling.style.maxHeight = "0";
      } else {
        // if there is an expanded item then close it
        let expandedItem = document.querySelector(
          ".accordion-list__item__header.expanded"
        );
        if (expandedItem) {
          expandedItem.classList.remove("expanded");
          expandedItem.nextElementSibling.style.maxHeight = "0";
        }

        // then expand the current item
        e.target.classList.add("expanded");
        e.target.nextElementSibling.style.maxHeight =
          e.target.nextElementSibling.scrollHeight + "px";
      }
      setTimeout(() => {
        console.log(window.scrollY > e.target.nextElementSibling.offsetTop);
        if (window.scrollY > e.target.offsetTop) {
          e.target.scrollIntoView({ behavior: "smooth" });
        }
      }, 200);
    }
  });

  // show first item
  let firstAccordionItem = document.querySelector(
    ".accordion-list__item__header"
  );
  firstAccordionItem && firstAccordionItem.click();
});
