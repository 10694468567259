// custom displayMessage func
export function displayMessage(msg, type) {
  // type >> current types (success, error)
  var alertElement = `
      <div class="p-3 mb-2 custom-alert-message ${type} text-white shadow d-flex align-items-center">
          <span class="mx-2" style="min-width: 175px;">${msg}</span>
      </div>
      `;

  // remove all alert elements
  document
    .querySelectorAll(".custom-alert-message")
    .forEach(alert => alert.remove());

  document.body.insertAdjacentHTML("beforeend", alertElement);

  var targetElement = document.querySelector(".custom-alert-message");
  targetElement.classList.add("show");
  setTimeout(() => targetElement.remove(), 4000);
  targetElement.addEventListener("click", function() {
    // targetElement.classList.remove("show");
    targetElement.remove();
  });
}
