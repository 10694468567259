let Validator = require("../vendors/js-form-validator.min");
import enValidationMessages from "./messages/en";
import { displayMessage } from "../helpers";

window.addEventListener("DOMContentLoaded", function() {
  let options = {
    errorClassName: "error-feedback",
    locale: "en",
    // custom rules
    rules: {},
    messages: {
      en: enValidationMessages
    }
  };

  // validate contact us form
  let contactUsForm = document.getElementById("contact-us-form");
  contactUsForm;
  if (contactUsForm) {
    let validatedForm = new Validator(
      contactUsForm,
      function(err, res) {
        // if form is valid
        if (res) {
          let submitBtn = contactUsForm.querySelector(".form__button");
          submitBtn.classList.toggle("button--loading");
          let formData = new FormData(contactUsForm);
          var dataObj = {};
          for (var key of formData.keys()) {
            dataObj[key] = formData.get(key);
          }

          fetch("https://api1.emalyami.com/core/api/v1/contact/send", {
            method: "post",
            headers: {
              'Content-Type': 'application/json'
              },
            body: JSON.stringify(dataObj)
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              if (data.status !== 400) {
                contactUsForm.reset();
                validatedForm.reload();
                displayMessage("Your Message was sent successfully", "success");
              } else {
                displayMessage(
                  "Something went wrong please try again :)",
                  "error"
                );
              }
            })
            .catch(err => {
              displayMessage(
                "Something went wrong please try again :)",
                "error"
              );
            })
            .finally(() => {
              submitBtn.classList.toggle("button--loading");
            });
        }
        return false;
      },
      options
    );
  }
});
