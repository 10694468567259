import "./form-validation";
import "./UI";
window.addEventListener("DOMContentLoaded", function() {
  let HeaderNav = document.getElementById("header__nav");
  // toggle header nav
  let toggleNavBtn = document.getElementById("header__toggle-nav-btn");
  toggleNavBtn.addEventListener("click", function() {
    HeaderNav.classList.toggle("show");
    document.body.classList.toggle("overflow-hidden");
    this.classList.toggle("open");
  });

  // header smooth scroll and add class active onscroll
  HeaderNav.addEventListener("click", e => {
    let targetLink = e.target.closest("[data-nav]");
    let targetSection = document.getElementById(targetLink.dataset.nav);
    if (targetLink && targetSection) {
      e.preventDefault();
      // close header nav
      if (window.innerWidth <= 991.98) toggleNavBtn.click();
      // scroll into target section
      document.documentElement.scrollTo({
        top: targetSection.offsetTop - 70,
        behavior: "smooth"
      });
    }
  });

  // init Tawk chat
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function() {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/61f17a619bd1f31184d97aa0/1fqbkc3q2";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();
});
